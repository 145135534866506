import React, { Component, useState, useRef, useEffect } from "react";
import Navbar from "./Navbar";
import { withRouter, Redirect, Link } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import { Helmet } from "react-helmet";
import { Input, Icon } from "react-materialize";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
   loginUserOtp,
   resendOTP,
   preSignin,
   getCurrencies,
  resendActivation,
  clearErrors,
} from "../actions/authActions";
import Spinner from "./common/Spinner";
import { ENV } from "../actions/config";
import "../stylesheets/App.css";
import CookieDisclaimer from "./common/CookieDisclaimer";
import { browserName } from "./common/Methods";
import { iconUrl } from "../actions/config";
import { TZ_WITH_CURRENCY } from "../utils/tzwithcountry";
import Swal from "sweetalert2";


const showMessage = (msg, type, time) => {
  Swal.fire({
    title: msg,
    position: 'bottom-start',
    toast: true,
    showConfirmButton: false,
    timer: time ? time : 2000,
    background: type==='success' ? '#8dc44e' : '#f1521c',
    customClass: {
        title: 'sweet-alert',
        container: 'sweet-alert-container'
    }
});
}

function growDiv(growDiv) {
  if (growDiv.clientHeight) {
    growDiv.style.height = 0;
  } else {
    var wrapper = growDiv.querySelector(".measuringWrapper");
    growDiv.style.height = wrapper.clientHeight + 20 + "px";
  }
}

let LoginInputPage = ({ t, history, currencies, loading, preSign,loginUserOtp, preSignin, resendOTP, slideTonext, formState,  setFormState}) => {
    loading=loading.loading
  const inputEl = useRef(null);
  console.log('history', history)
  const googleLogin = () => {
    window.location.href = `${ENV.API}auth/google/auth`; // Redirect to your backend /auth route
  }
  return (
    <div className="card-content">
      <div className="center-align" style={{marginBottom: '25px'}}>
        <img src={iconUrl + "account.png"} height="60px" alt="UserImage" />
        <span className="card-title">
          {" "}
          <h5>
            <Trans>SIGN_IN</Trans>
          </h5>
        </span>
        <span>
          <Trans>TO_TIMELYBILLS_ACCOUNT</Trans>
        </span>
        {formState.showRefralMessage ? <span>
          <span className="card-title mb-2 font-size-1rem"> <Trans>MULTIUSER_REGISTRATION_TEXT</Trans> {formState.ownerFirstName || formState.ownerEmail}</span>
        </span>: null}
      </div>

      <form onSubmit={e=>{
         let currency = '';
         try {
          currency =  TZ_WITH_CURRENCY[Intl.DateTimeFormat().resolvedOptions().timeZone]
         } catch(e) {}

          if(preSign) {
            preSignin({email: formState.email, deviceId: 'WEB',  currency: currency, grpToken: formState.groupToken},  e=>{
              console.log('preSignin', e)
              if (e.code !== 340) { // Proceed only if there is no error
                  setFormState({ ...formState, count: 60, isNewUserSignup: false });
                  slideTonext();
              }else{
                showMessage(t('ACCOUNT_NOT_EXISTS'));
              }
            });
          } else {
            loginUserOtp({email: formState.email, otp: formState.otp,
              deviceType: "WEB",
              deviceId: "WEB",
              deviceName: browserName()}, e=>{
                history.replace('/loadingprofile');
              }, e=>{
                showMessage(t('INVALID_OTP'));
              });
          }
          e.preventDefault();
          e.stopPropagation();
          return false;
      }}>
        <div className="row margin-0">
          <div className="col m1" />
          <div className="input-field col m10 s12">
            <input
              id="icon_prefix"
              type="email"
              disabled={formState.groupToken !== undefined}
              style={formState.groupToken !== undefined ? {background: '#d1d1d1'}: {}}
              value={formState.email}
              onChange={e=>setFormState({...formState, email: e.target.value})}
              placeholder={""}
              name="email"
              required
            />
            <label htmlFor="icon_prefix" className={"active"}>
              <Trans>EMAIL</Trans>
            </label>
            
          </div>
        </div>
        {!preSign ? (
          <>
            <div className="row margin-0">
              <div className="col m1" />
              <div className="input-field col m10 s12 hide-show-pass">

                <input
                  type='text'
                  name="otp"
                  className="c-margin-0"
                  placeholder={""}
                  value={formState.otp}
                  onChange={e=>setFormState({...formState, otp: e.target.value})}
                  required
                />
                <label htmlFor="icon_prefix" className={"active"}>
                  <Trans>OTP</Trans>
                </label>
              </div>
              <div
                className="col m11 s12"
                style={{  fontSize: "11px" }}
              >
                <div className="col m1" />
                <div className="col m11" >
                  <Trans>OPT_SENT_ALREADY</Trans>
                  <span style={{ float: "right", color: "#039be5", cursor: 'pointer', marginTop: '20px' }} 
                     onClick={e=>resendOTP(formState.email, e=>showMessage(t('OPT_SENT_ALREADY'), 'success'), e=>showMessage(t('OPT_SENT_ERROR')))}>
                    <Trans>RESEND</Trans>
                  </span>
                </div>
              </div>
            </div>
            {formState.isNewUserSignup?
              <div className="row margin-0">
                                                <div className="col m1" />
                                                <div className="input-field  col m10 s12 last-input">
                                                    <Input s={12} type='select' defaultValue='0' onChange={
                                                      e=>setFormState({...formState, currency: e.target.value})
                                                    } name="currency">
                                                        <option value='0' disabled>{t('SELECT_CURRENCY')}</option>
                                                        {
                                                            currencies
                                                                .map((currency) =>
                                                                    <option
                                                                        value={currency.currencyCode}
                                                                        key={currency.currencyCode}
                                                                    >
                                                                        {currency.currencyName}
                                                                    </option>
                                                                )
                                                        }
                                                    </Input>
                                                    <label htmlFor="icon_prefix" style={{top: '-1rem'}}><Trans>CURRENCY</Trans></label>
                                                </div>
                                            </div>
            :null }
            <div className="row margin-0">
              <div className="col m1" />
              <div className="col m11" >
              <div className="input-field col l6 m5 s6 width-100 signButton">
                <button
                  className="btn waves-effect waves-light light-blue accent-4"
                  type="submit"
                  name="action"
                >
                  <Trans>SIGN_IN</Trans>
                </button>
              </div>
              <div className="input-field col l5 m5 s5 orLink width-100">
                <div
                  onClick={(e) => growDiv(inputEl.current)}
                  className="forgot-password-font"
                  style={{ color: "#039be5", cursor: "pointer" }}
                >
                  {" "}
                  <Trans>MSG_SIGN_HELP</Trans>
                </div>
              </div>
              </div>
            </div>{" "}
          </>
        ) : null}
        {!preSign ? 
        
                                            null
        
        : (
          <div className="row margin-0">
            
               {loading ? (
                             <div style={{ width: "50px", margin:'auto' }}><Spinner /></div>
                            ) : 
                            <div style={{ width: "200px", margin:'auto' }}><button
                className="btn waves-effect waves-light light-blue accent-4"
                style={{ width: "200px", margin:'auto' }}
                type="submit"
                name="action"
              >
                <Trans>NEXT</Trans>
              </button></div>}
              <div className="center-align" style={{ marginTop: "10px", marginBottom: "10px", color: "#2196F3", fontWeight: "bold" }}>
                OR
              </div>
            </div>
        )}
      </form>
{ !preSign ? null :     <div className="center-align">
                <button className="google-btn" onClick={googleLogin}>
                  Continue with 
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/640px-Google_2015_logo.svg.png"
                    alt="Google Logo"
                    className="google-logo"
                    width={60}
                    height={20}
                  />
                </button>
              </div>}
      <div className="row margin-0">
      <div className="col m1" ></div>
      <div className="col m10" >
      <div className="grow" ref={inputEl} style={{marginTop: '25px'}}>
        <div className="measuringWrapper" style={{borderTop: '1px solid #000'}}>
          <div className="text">
            <div className="listd"><Trans>MSG_SIGN_HELP_1</Trans></div>
            <div className="listd"><Trans>MSG_SIGN_HELP_2</Trans></div>
            <div className="listd"><Trans>MSG_SIGN_HELP_3</Trans></div>
            <div className="listd"><Trans>MSG_SIGN_HELP_4</Trans></div>
          </div>
        </div>
      </div>
      </div>
      </div>

      {!preSign ? <div className="row margin-0 by-clicking">
        <br/><br/>
                                                <span><Trans>CLICKING_SIGNIN</Trans></span>
                                                <br/>
                                                <a href="https://timelybills.app/terms-of-service" target='_blank' rel='noopener noreferrer'> <Trans>TERMS_OF_SERVICE</Trans></a>
                                                <br/>
                                                <a href="https://timelybills.app/privacy-policy" target='_blank' rel='noopener noreferrer'> <Trans>PRIVACY_POLICY</Trans></a>
                                            </div> : null }
    </div>
  );
};



const SingInMail = (props) => {
  console.log('props', props)
  const [formState, setFormState] = useState({showPass: false});
  const { currencies = [] } = props.auth;
  useEffect(e=>{
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status'); 
    console.log('status', status)
    if(status && status.length > 0 && status === '340') {
      showMessage(props.t('ACCOUNT_NOT_EXISTS'), null, 10000);
      const newUrl = window.location.origin + window.location.pathname;
      window.history.pushState({}, document.title, newUrl);
    }
    if(localStorage.getItem('jwtToken')) {
    props.history.replace('/loadingprofile');
    }

    if(props.location.state && props.location.state.userData && props.location.state.userData.inviteeEmail) {
      setFormState({...formState, email: props.location.state.userData.inviteeEmail, 
        ownerFirstName : props.location.state.userData.ownerFirstName,
        ownerEmail: props.location.state.userData.ownerEmail,
        groupToken: props.location.state.token,
        showRefralMessage: true});
    }
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>{props.t("SIGN_IN")}</title>
        </Helmet>
        <div>
          <Navbar disableMenu={true} disableSideLink={true} />
          <div className="vertical-center">
            <div className="row margin-0 sign-in-section" style={{marginTop: '40px'}}>
              <div className="col l12 m12 s12">
                <div className="card darken-1">
                  <SlideComponent
                    {...props}
                    currencies={currencies}
                    formState={formState}
                    setFormState={e=>setFormState(e)}
                    FirstComponent={LoginInputPage}
                    SecondComponent={LoginInputPage}
                  />
                  {/* <LoginInputPage t={t} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CookieDisclaimer />
      </div>
    </>
  );
};

const SlideComponent = (props) => {
  //momenttz.tz(momenttz.tz.guess())

const { FirstComponent, SecondComponent } = props;
  const slider = useRef(null);
  const slideTonext = e=>{
    slider.current.classList.add("next");
  }
  return (
    <div
      className="slidediv-root"
    >
      <div ref={slider}>
        <div>{<FirstComponent {...props} preSign slideTonext={slideTonext} />}</div>
        <div>{<SecondComponent {...props}  />}</div>
        <div />
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  currencies: state.currencies,
  auth: state.auth,
  errors: state.errors,
  loading: state.loading,
});



export default withTranslation()(
  connect(
    mapStateToProps,
    {
    loginUserOtp,
    resendOTP,
    preSignin,
    resendActivation,
    getCurrencies,
    clearErrors,
    }
  )(withRouter(SingInMail))
);
